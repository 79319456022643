@import 'base';
@import 'utils';
@import 'animation';

@font-face {
  font-family: 'PingFangSC';
  src: url('../fonts/PingFangSC-Regular.woff2'), url('../fonts/PingFangSC-Semibold.woff2');
}

html {
  font-size: 14px;
}

body {
  font-family: PingFangSC;
  //background-image: linear-gradient(212deg, #1b1d20 102%, #20252b 79%, #000000 13%);
}

@media screen and (min-width: num2px($responsive_width)) {
  body,
  #root {
    // background: linear-gradient(54deg, #000000 19%, #20252b 76%, #1b1d20 95%);
    background: linear-gradient(252deg, #111111 0%, #0B0D0E 100%);
    overflow: auto !important;
  }
}

#root {
  color: #fff;
  @media screen and (max-width: num2px($responsive_width - 1)) {
    background: linear-gradient(
      0deg,
      #0a0a0a 0%,
      #0b0c0c 22%,
      #1f2226 34%,
      #171a1e 40%,
      #111214 60%,
      #20252b 85%,
      #050506 100%
    );
  }
}

.width100p {
  width: 100%;
}

.width100vw {
  width: 100vw;
}

.flex-justify-content-center {
  display: flex;
  justify-content: center;
}

.mars-enter,
.mars-appear {
  opacity: 0;
  transform: scale(0.4);
}

.mars-enter-active,
.mars-appear-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 500ms, transform 300ms;
}

.mars-exit {
  opacity: 1;
  transform: scale(1);
}

.mars-exit-active {
  opacity: 0;
  transform: scale(0.4);
  transition: opacity 300ms, transform 300ms;
}

.mars-exit-done {
  opacity: 0;
}

.button-reset {
  @include toScale();
  border: 0 !important;
  background-image: linear-gradient(247deg, #f49f17, #f13e12) !important;
  border-radius: px2rem(4) !important;
}

.rc-tooltip-inner {
  background-color: transparent !important;
}

.rc-tooltip-arrow {
  display: none;
}
