@function vw($px) {
  @return $px/1440 * 100 + vw;
}

@function vh($px) {
  @return $px/761 * 100 + vh;
}

@function px2rem($px) {
  @return $px/14 + rem;
}

@function num2px($value) {
  @return $value + px
}

@mixin toScale($value: 1.1, $time: 0.5s) {
  transition: scale $time;
  &:hover {
    transform: scale($value);
  }
}

@mixin toScaleY($value: 1.1, $time: 0.5s) {
  transition: scaleY $time;
  &:hover {
    transform: scaleY($value);
  }
}
